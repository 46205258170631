import Head from 'next/head';
import Router from 'next/router';
import { useContext, useEffect } from "react";
import { Row, Col } from 'antd';

import AppContext from "../src/common/_context/AppContext";
import { verifyToken } from '../src/lib/fetchApi';

import styles from "../src/styles/pages/Login.module.scss";

export default function Home() {
  const [store, setStore] = useContext(AppContext);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (null === token) {
      Router.push('/login');
    } else {
      setStore({...store, token: token})
      const fetchData = async () => {
        let response = await verifyToken(token.token);
      };
  
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Head>
        <title>Scaprim Nateo</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className={styles.page}>
        <div className={styles.bg}>
          <img
            src="/img/login-bg.jpg"
            alt="Background image"
            className={styles.bg_img}
          />
        </div>
        <div className={styles.login}>
          <div className={styles.header}>
            <img
              src="/logo-scaprim.svg"
              alt="Logo Scaprim Nateo"
              className={styles.header_logo}
            />
            <img src="/logo-nateo.svg" alt="Logo Scaprim Nateo" width={150} />
          </div>
        </div>
      </div>
    </div>
  )
}
